import { GetStaticProps } from 'next';
import React, { FC, useEffect } from 'react';
import useSWR from 'swr';

import { PageRenderer } from 'Components';
import { PageType } from 'Entities';
import { getStoryblokPage } from 'Utils/Storyblok.util';

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const response = await getStoryblokPage('home');

  if (!response) return { notFound: true };

  return { props: { pageData: response }, revalidate: 1 };
};

interface PageRouteProps {
  pageData: { story?: PageType };
}

const HomePageRoute: FC<PageRouteProps> = (props) => {
  console.log('PageRoute', { props });
  const pageUrl = 'home';

  const { data, mutate } = useSWR(pageUrl, getStoryblokPage, {
    fallbackData: props?.pageData,
  });

  useEffect(() => {
    window.storyblok.init({ accessToken: 'pzV4fS94fnkfaDvS4ieIAgtt' });
    window.storyblok.on(['change', 'published'], () => mutate());
  }, []);

  return <PageRenderer page={data?.story} />;
};

// eslint-disable-next-line import/no-default-export
export default HomePageRoute;
